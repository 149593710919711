
import { computed, defineComponent,onMounted } from "vue";
import BuyNew from "@/views/pages/buy/buy-new.vue";
import LinkGenerate from "@/views/pages/buy/linkGenerate.vue";
import UserInfo from "@/core/services/UserInfo";
import BuyHandler from "@/views/pages/buy/Buy/BuyHandler.vue";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/services/HelpersFun";

export default defineComponent({
  name: "handle-component-buy",
  components: {
    BuyNew,
    LinkGenerate,
    BuyHandler,
  },
  setup() {
    const route = useRoute();


    const user = computed(() => {
      return UserInfo.getUser();
    });

      onMounted(() => {
       setCurrentPageBreadcrumbs(translate(route.name === 'buy-link-generate' ?"ایجاد لینک خرید" :"خرید اشتراک"), []);
    });

    return {
      user,
      route,
    };
  },
});
