
import { defineComponent, onMounted, reactive, ref, watchEffect } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import { ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import DatePicker from "vue3-persian-datetime-picker";
import { useI18n } from "vue-i18n";
import { hideModal, showModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "kt-card-to-card-info-modal",
  components: {
    Field,
    ErrorMessage,
    DatePicker,
  },
  props: {
    bot_id: {
      type: String,
      default: null,
    },
    open_flag: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    enable_tax: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    //  onMounted(() => {
    // });
    const router = useRouter();

    const store = useStore();
    const modalref = ref<HTMLElement | null>(null);

    watchEffect(() => {
      if (props.show === true) {
        showModal(modalref.value);
      }
    });

    const submitButton1 = ref<HTMLElement | null>(null);
    const route = useRoute();
    const formData = reactive({
      bot: props.data.bot,
      PayWithCardIssueTracking: "",
      PayWithCardPrice: props?.data?.payablePrice,
      PayWithCardDate: null,
    });
    const submitPayWithCard = ref<HTMLElement | null>(null);

    // ایجاد ربات
    const saveBot = async () => {
      try {
        const result = await ApiService.post("bot/create", {
          name: props.data.name,
        });
        return result.data.data.bot;
      } catch (e) {
        store.commit(Mutations.SET_ERROR, e.response.data);
        if (submitPayWithCard.value)
          submitPayWithCard.value?.removeAttribute("data-kt-indicator");
        if (submitPayWithCard.value)
          submitPayWithCard.value?.removeAttribute("data-kt-indicator");
      }
    };

    const payWithCard = async () => {
      // پرداخت کارت به کارت
      submitPayWithCard.value?.setAttribute("data-kt-indicator", "on");

      if (!formData.bot) formData.bot = await saveBot();
      if (!formData.bot) return;
      ApiService.post("user/credit/charge", {
        cash: "yes",
        cash_info: {
          tracking_code: formData.PayWithCardIssueTracking,
          date: formData.PayWithCardDate,
          amount: formData.PayWithCardPrice,
          receipt: importFile.value,
        },
        enable_tax: props.enable_tax ? 1 : 0,
        boundless_mode: props.data.boundless_mode,
        package_id: props.data.selectedPackage.id,
        platforms: props.data.platforms,
        discount_code:
          props.data.appliedDiscountCode === true && props.data.discountCode
            ? props.data.discountCode
            : undefined,
        amount: props.data.payablePrice,
        bot_id: formData.bot.bot_id,
      })
        .then(() => {
          Swal.fire({
            text: "درخواست شما ثبت شد. همکاران ما پس از بررسی و حداکثر تا دو ساعت (در ساعات کاری) تراکنش شما را تایید می‌کنند.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: translate("ok"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
            },
          }).then(() => {
            hideModal(modalref.value);
            router.push({ name: "dashboard" });
          });
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          submitPayWithCard.value?.removeAttribute("data-kt-indicator");
        });
    };

    const uploadActionUrl = (type) => {
      if (type === "excel")
        return process.env.VUE_APP_UPLOAD_FILE_URL + `upload/excel`;
      return process.env.VUE_APP_UPLOAD_FILE_URL + `upload/file`;
    };

    const importFile = ref();
    const disableSubmit = ref(false);

    const uploadExcelCallback = (res) => {
      importFile.value = res.data.path;
      disableSubmit.value = false;
    };

    const excelErrorCallback = () => {
      disableSubmit.value = false;
      ElNotification({
        title: translate("error"),
        message: translate("Please upload a file in xls or csv format"),
        type: "error",
      });
    };

    return {
      submitButton1,
      formData,
      route,
      uploadActionUrl,
      uploadExcelCallback,
      excelErrorCallback,
      translate,

      payWithCard,
      submitPayWithCard,
      modalref,
      importFile,
    };
  },
});
