
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { platforms } from "@/store/pinia/Platforms";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "kt-platform-features-list",
  emit: ["confirmEmail"],
  props: {
    platforms: {
      type: Array,
      default: null,
    },
    classes: {
      type: String,
      default: "",
    },
    showAllInBoundleMode: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const platformsHolder = platforms();
    const { platformsList } = storeToRefs(platformsHolder);

    return {
      translate,
      platformsList,
    };
  },
});
