import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkGenerate = _resolveComponent("LinkGenerate")!
  const _component_BuyNew = _resolveComponent("BuyNew")!
  const _component_BuyHandler = _resolveComponent("BuyHandler")!

  return (_ctx.user && _ctx.user.country && _ctx.user.country.alpha2_code === 'IR')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.route.name === 'buy-link-generate')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_LinkGenerate)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_BuyNew)
            ]))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createVNode(_component_BuyHandler)
      ]))
}