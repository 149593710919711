
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import userInfo from "@/core/services/UserInfo";
import { supportPhoneNumber } from "@/core/services/LanguageData";
import Currency from "@/components/CurrencyCode.vue";
export default defineComponent({
  name: "kt-invoice-template",
  components: { Currency },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  setup: function () {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const user = computed(() => {
      return userInfo.getUser();
    });

    return {
      translate,
      user,
      supportPhoneNumber,
    };
  },
});
