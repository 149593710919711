export const supportPhoneNumber = () => {
  const lang = localStorage.getItem("lang");
  let phone;
  switch (lang) {
    case "fa":
      phone = "021-91690322";
      break;
    case "tr":
      phone = "021-91690322";
      break;
    case "en":
      phone = "021-91690322";
      break;
    default:
      phone = "021-91690322";
      break;
  }

  return phone;
};

export default { supportPhoneNumber };
