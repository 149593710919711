
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { translate } from "@/core/services/HelpersFun";
export default defineComponent({
  name: "input-verify-codes",
  props: {
    max: {
      type: Number,
      default: 4,
    },
    codetype: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    reset_codes: {
      type: Boolean,
      default: false,
    },
  },
  emit: ["codes"],
  setup: function (props: any, { emit }) {
    const codes = ref({});
    const route = useRoute();
    const string_code = ref();
    const checkCode = ref(true);
    const pushData = () => {
      for (let step = 0; step < props.max; step++) {
        codes.value[step] = "";
      }
    };


    const check = () => {
      let sum = "";
      for (let step = 0; step < props.max; step++) {
        if (codes.value[step] !== "") {
          sum += codes.value[step];
        }
      }
      if (sum.length === props.max && checkCode.value === true){
        if (
          props.codetype === "number" ||
          route.name === "sign-in" ||
          route.name === "account-settings"
        ) {
          emit("codes", sum, props?.target);
          checkCode.value = false
        }
      }
    };

    watch(codes.value, () => {
      return;
      // let sum = "";
      // for (let step = 0; step < props.max; step++) {
      //   if (codes.value[step] !== "") {
      //     sum += codes.value[step];
      //   }
      // }
      // if (sum.length === props.max) {
      //   if (
      //     props.codetype === "number" ||
      //     route.name === "sign-in" ||
      //     route.name === "account-settings"
      //   ) {
      //     emit("codes", sum, props?.target);
      //   }
      // }
    });

    watch(string_code, () => {
      if (props.codetype === "string") {
        emit("codes", string_code.value, props?.target);
      }
    });

    watch(props, () => {
      for (let step = 0; step < props.max; step++) {
        codes.value[step] = "";
      }
    });

    onMounted(() => {
      pushData();

      const inputItem = document.getElementById("input_0");
      if (inputItem) {
        inputItem.focus();
      }
    });

    // new

    const otpValue = ref(Array.from({ length: props.max }, () => ""));

    let isInProgress = ref(false);

    const handleInputElsInput = (e, index) => {
      const val = e.target.value.replace(/[^0-9]/g, "");
      if (!val || isNaN(val)) {
        e.target.value = "";
        updateOtpValue();
        return;
      }
      let idx = 0;
      let el = e.target;
      if (val.length === 0) {
        updateOtpValue();
        return;
      }
      if (isInProgress.value) return;
      isInProgress.value = true;
      if (val.length === 1) {
        el.value = val;
        el = el?.nextElementSibling ?? null;
      }
      if (val.length === 2) {
        el.value = val.substring(1, 2);
        el = el?.nextElementSibling ?? null;
      }
      while (val.length > 2 && idx < val.length && !!el) {
        el.value = val.substring(idx, idx + 1);
        el = el?.nextElementSibling ?? null;
        idx++;
      }
      el?.focus();
      isInProgress.value = false;
      updateOtpValue();
    };

    const inputEls = ref();

    const updateOtpValue = () => {
      otpValue.value.forEach((v, i) => {
        otpValue.value[i] = inputEls.value[i].value;
      });
    };

    const addInputEls = (el) => {
      const alreadyIn = inputEls.value.findIndex((v) => v.id === el.id) > -1;
      if (alreadyIn) return;
      inputEls.value.push(el);
    };

    
    const backSpace = (e) => {
      if (e.key === "Backspace" || e.key === "Delete") {
        if (e.target.previousElementSibling.value.length > 0) {
          e.target.previousElementSibling.focus();
        }
      }
    };

    onMounted(() => {
      let el = inputEls.value;
      if (Array.isArray(el)) el = el[0];
      el?.focus();
    });

    return {
      translate,
      codes,
      string_code,
      check,

      // NEW
      handleInputElsInput,
      addInputEls,
      backSpace,
    };
  },
});
