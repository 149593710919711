
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  ref,
  watch,
  watchEffect,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { getIllustrationsPath } from "@/core/helpers/assets";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
// import ShowCardToCardInfoModal from "@/views/pages/buy/ShowCardToCardInfoModal.vue";
import router from "@/router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import { useRoute } from "vue-router";
import { platforms } from "@/store/pinia/Platforms";
import { storeToRefs } from "pinia";
import { AllBots } from "@/store/pinia/AllBots";
import { allPackages } from "@/store/pinia/AllPackages";
import Currency from "@/components/CurrencyCode.vue";
import ClipboardJS from "clipboard";
import PlatformFeatures from "@/components/PlatformFeatures.vue";
import { ElNotification } from "element-plus/es";

export default defineComponent({
  name: "buy-package-stepper",
  components: {
    Currency,
    Field,
    Form,
    ErrorMessage,
    // ShowCardToCardInfoModal,
    PlatformFeatures,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: "new",
    },
  },
  setup(props) {
    const userIdForLinkGenerate = ref();
    const userBotIdForLinkGenerate = ref("");
    const linkModal = ref(false);
    const infoModal = ref(false);
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const route = useRoute();

    onUpdated(() => {
      handleBotFromProps();
    });

    onMounted(() => {
      setTimeout(() => {
        handleBotFromProps();
      }, 1000);
    });

    const handleBotFromProps = () => {
      if (props.bot) {
        formData.value.bot = props.bot;
        // formData.value.name = props.bot?.name;
      }
    };

    const store = useStore();
    const buy_type = ref();
    // const platforms = ref();
    const platformsHolder = platforms();
    const { platformsList, activePlatforms, activePlatformsKeys } =
      storeToRefs(platformsHolder);

    const packageListHolder = allPackages();
    const { packageList } = storeToRefs(packageListHolder);

    const formData = ref({
      name: computed(() => {
        return formData.value.selectedPackage?.name;
      }),
      platforms: [],
      defaultPackageId: 105,
      selectedPackage: {
        id: "",
        platforms_price: [],
      },
      credit: 0,
      taxPrice: 0,
      bot: "" as any,
      discountCode: "",
      discountPrice: 0,
      appliedDiscountCode: undefined,
      discountStatus: "" as any,
      prices: 0,
      payablePrice: 0,
      enable_tax: false,
      boundless_mode: false,
      sumPlatformPrices: computed(() => {
        const prices = [] as any;
        Object.entries(formData.value.selectedPackage.platforms_price).forEach(
          ([key, value]) => {
            if (
              platformsList.value[key] &&
              platformsList.value[key].status === true
            ) {
              prices.push(value);
            }
          }
        );

        return prices.reduce((accumulator, currentValue) => {
          return accumulator + parseInt(currentValue, 10);
        }, 0);
      }),
    });

    // clear selected platform when click in boundless mode
    watch(
      () => formData.value.boundless_mode,
      () => {
        formData.value.platforms = [];
      }
    );

    // clear selected platform when click in boundless mode
    watch(
      () => formData.value.platforms,
      () => {
        if (formData.value.platforms.length > 0)
          formData.value.boundless_mode = false;
      }
    );
    watch(
      () => userIdForLinkGenerate.value,
      () => {
        fetchPackageList();
        fetchPlatforms();
      }
    );

    watchEffect(() => {
      formData.value.selectedPackage = packageList.value
        ? packageList.value.find(
            (item) => item.id === formData.value.defaultPackageId
          )
        : {
            id: "",
            platforms_price: [],
          };
    });

    const AllBotHolder = AllBots();
    const { fetchBotList } = AllBotHolder;

    const saveBot = async () => {
      try {
        const result = await ApiService.post("bot/create", {
          name: formData.value.name,
        });
        return result.data.data.bot;
      } catch (e) {
        store.commit(Mutations.SET_ERROR, e.response.data);
        if (submitPayWithCredit.value)
          submitPayWithCredit.value?.removeAttribute("data-kt-indicator");
        if (submitPayOnline.value)
          submitPayOnline.value?.removeAttribute("data-kt-indicator");
      }
    };

    const sendParameter = computed(() => {
      return {
        bot_id:
          route.name === "buy-link-generate"
            ? userBotIdForLinkGenerate.value
            : formData.value.bot?.bot_id,
        package_id: formData.value.selectedPackage.id,
        platforms: formData.value.platforms,
        // enable_tax: formData.value.enable_tax ? 1 : 0,
        enable_tax: 1,
        boundless_mode: formData.value.boundless_mode ? 1 : 0,
        discount_code:
          formData.value.appliedDiscountCode === true &&
          formData.value.discountCode
            ? formData.value.discountCode
            : undefined,
        user_id: userIdForLinkGenerate.value, // this for link generator page
      };
    });

    const submitPayWithCredit = ref<HTMLElement | null>(null);
    const chargeCredit = async () => {
      // پرداخت آنلاین از درگاه
      submitPayWithCredit.value?.setAttribute("data-kt-indicator", "on");

      if (!formData.value.bot) formData.value.bot = await saveBot();
      if (!formData.value.bot) return;

      try {
        const result = await ApiService.post(
          "user/credit/charge",
          sendParameter.value
        );
        await fetchBotList();
        window.location.href = result.data.data.port_url;
      } catch ({ response }) {
        store.commit(Mutations.SET_ERROR, response.data);
      } finally {
        submitPayWithCredit.value?.removeAttribute("data-kt-indicator");
      }
    };

    const addDataLayerPurchase = () => {
      let data = {
        event: "purchase",
        ecommerce: {
          value: Number(formData.value.payablePrice),
          tax: Number(formData.value.taxPrice) ?? 0,
          currency: "USD",
          coupon: formData.value.discountCode,
          coupon_discount: Number(formData.value.discountPrice),
          transaction_id: `free_activation_${
            Math.floor(Math.random() * (1000000000 - 1 + 1)) + 1
          }`,
          items: [] as any,
        },
      };

      formData.value.platforms.forEach((item, i) => {
        data.ecommerce.items.push({
          item_name: item,
          item_variant: formData.value.selectedPackage?.name,
          item_brand: "پیام رسان اتومیشن انبوه",
          index: i,
          price: Number(formData.value.selectedPackage.platforms_price[item]),
          quantity: 1,
        });
      });

      const dataLayerScript = document.createElement("script");
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(${JSON.stringify(data)});
      `;

      document.head.appendChild(dataLayerScript);
    };

    const submitPayOnline = ref<HTMLElement | null>(null);
    const payWithCredit = async () => {
      // پرداخت با کیف پول
      submitPayOnline.value?.setAttribute("data-kt-indicator", "on");
      if (!formData.value.bot) formData.value.bot = await saveBot();
      if (!formData.value.bot) return;

      try {
        const result = await ApiService.post("bot/buy", sendParameter.value);
        await fetchBotList();
        formData.value.bot = result.data.data.bot;
        addDataLayerPurchase();
        router.push(
          "/bot-connections/connect/" +
            formData.value.bot?.bot_id +
            `/${formData.value.platforms[0]}`
        );
      } catch ({ response }) {
        store.commit(Mutations.SET_ERROR, response.data);
      } finally {
        submitPayOnline.value?.removeAttribute("data-kt-indicator");
      }
    };

    const loadingPrice = ref(false);
    const calculatePrice = () => {
      if (
        Object.keys(formData.value.platforms).length < 1 &&
        formData.value.boundless_mode === false
      ) {
        formData.value.payablePrice = 0;
        formData.value.prices = 0;
        formData.value.discountPrice = 0;
        formData.value.credit = 0;
        return false;
      }

      let source = null as any;
      if (source) source.cancel("Operation canceled by the user.");
      source = axios.CancelToken.source();

      loadingPrice.value = true;
      ApiService.query(`bot/calculate-price`, {
        cancelToken: source.token,
        params: {
          cancelToken: source.token,
          platforms: formData.value.platforms,
          package_id: formData.value.selectedPackage?.id,
          enable_tax: formData.value.enable_tax ? 1 : 0,
          boundless_mode: formData.value.boundless_mode ? 1 : 0,
          user_id: userIdForLinkGenerate.value,
          discount_code:
            formData.value.appliedDiscountCode === true &&
            formData.value.discountCode
              ? formData.value.discountCode
              : undefined,
        },
      })
        .then(({ data }) => {
          // formData.value.discountStatus = formData.value.discountCode
          //   ? true
          //   : undefined;

          formData.value.payablePrice = data.data.price.payablePrice;
          formData.value.prices = data.data.price.basePrice;
          formData.value.discountPrice = data.data.price.discountPrice;
          formData.value.credit = data.data.price.userCredit;
          formData.value.taxPrice = data.data.price.taxPrice;

          if (Number(data.data.price.discountPrice) > 0) {
            formData.value.discountStatus = true;
          } else {
            formData.value.appliedDiscountCode = undefined;
          }
          loadingPrice.value = false;
        })
        .catch((e) => {
          if (e.response?.data)
            store.commit(Mutations.SET_ERROR, e.response?.data);
          if (formData.value.discountCode) {
            formData.value.discountStatus = "false";
            formData.value.appliedDiscountCode = undefined;
          }
          if (!axios.isCancel(e)) {
            loadingPrice.value = false;
          }
        });
    };

    const users = ref();
    const loadingSearchUser = ref(false);
    async function performSearch(searchTerm) {
      loadingSearchUser.value = true;
      const response = await ApiService.query("admin/user", {
        params: { q: searchTerm },
      });

      const results = await response;
      if (results && results.data) {
        loadingSearchUser.value = false;
        users.value = results.data.data.users.data;
      }
    }

    const link = ref("");

    const copyButtonRef = ref<null | HTMLElement>(null);
    const inputRef = ref<null | HTMLElement>(null);

    const handleCopy = () => {
      const clipboard = new ClipboardJS(copyButtonRef.value as HTMLElement);

      clipboard.on("success", function (e) {
        const buttonCaption = copyButtonRef.value?.innerHTML;

        //Add bg color
        inputRef.value?.classList.add("bg-success");
        inputRef.value?.classList.add("text-inverse-success");

        if (copyButtonRef.value) {
          copyButtonRef.value.innerHTML = translate("copied");
        }

        setTimeout(function () {
          if (copyButtonRef.value && buttonCaption) {
            copyButtonRef.value.innerHTML = buttonCaption;
          }

          // Remove bgcolor
          inputRef.value?.classList.remove("bg-success");
          inputRef.value?.classList.remove("text-inverse-success");
        }, 3000); // 3seconds

        e.clearSelection();
      });
    };

    // get data
    const fetchPackageList = async () => {
      try {
        const response = await ApiService.query("packages", {
          params: { user_id: userIdForLinkGenerate.value },
        });
        packageList.value = response.data.data.packages;
      } catch (error) {
        if (error.response && error.response.data) {
          store.commit(Mutations.SET_ERROR, error.response.data);
        }
      }
    };

    const fetchPlatforms = async () => {
      try {
        const { data } = await ApiService.query("platforms", {
          params: { user_id: userIdForLinkGenerate.value },
        });
        platformsList.value = data.data.platforms;
      } catch (error) {
        if (error.response && error.response.data)
          store.commit(Mutations.SET_ERROR, error.response.data);
      }
    };

    onMounted(() => {
      if (route.name === "buy")
        setCurrentPageBreadcrumbs(translate("Buy a subscription"), [
          translate("My subscriptions"),
        ]);
    });

    const linkGeneratorBuyPackage = async () => {
      try {
        const { data } = await ApiService.post(
          "admin/bot/buy",
          sendParameter.value
        );
        return data.data;
      } catch (error) {
        if (error.response && error.response.data)
          store.commit(Mutations.SET_ERROR, error.response.data);
      }
    };

    const linkGeneratorFactorCreate = async () => {
      try {
        const { data } = await ApiService.post(
          "admin/factor/create",
          sendParameter.value
        );
        link.value = data.data.pay_url;
         ElNotification({
          title: "تایید",
          message: "پیامک برای کاربر ارسال شد",
          type: "success",
        });
        return data.data;
      } catch (error) {
        if (error.response && error.response.data)
          store.commit(Mutations.SET_ERROR, error.response.data);
      }
    };

    const loadingBots = ref(false);
    const userBotList = ref();
    const getUserBotList = () => {
      loadingBots.value = true;
      ApiService.query("admin/bot", {
        params: {
          user_id: userIdForLinkGenerate.value,
        },
      })
        .then(({ data }) => {
          loadingBots.value = false;
          userBotList.value = data.data.bots.data;
          link.value = data.data.pay_url;
        })
        .catch(() => {
          loadingBots.value = false;
        });
    };

    const submitGenerateLinkButton = ref<HTMLButtonElement | null>(null);
    const handleLinkGenerator = async () => {
      linkModal.value = false;
      //handleLinkGenerator
      if (submitGenerateLinkButton.value) {
        // eslint-disable-next-line
        submitGenerateLinkButton.value!.disabled = true;
        // Activate indicator
        submitGenerateLinkButton.value.setAttribute("data-kt-indicator", "on");
      }
      if (formData.value.payablePrice === 0) {
        // await linkGeneratorBuyPackage();
        infoModal.value = true
      } else {
        await linkGeneratorFactorCreate();
      }
      if (submitGenerateLinkButton.value) {
        //Deactivate indicator
        submitGenerateLinkButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitGenerateLinkButton.value!.disabled = false;
      }
    };

    const sortObj = (obj) => {
      return Object.keys(obj)
        .sort()
        .reduce(function (result, key) {
          result[key] = obj[key];
          return result;
        }, {});
    };

    return {
      linkModal,
      infoModal,
      getIllustrationsPath,
      translate,
      calculatePrice,
      fetchPackageList,
      fetchPlatforms,
      formData,
      route,
      platformsList,
      activePlatforms,
      packageList,

      loadingPrice,
      chargeCredit,
      payWithCredit,

      submitPayWithCredit,
      submitPayOnline,
      sendParameter,
      userIdForLinkGenerate,
      users,
      loadingSearchUser,
      performSearch,
      link,
      copyButtonRef,
      inputRef,
      handleLinkGenerator,

      handleCopy,
      getUserBotList,
      userBotList,
      userBotIdForLinkGenerate,
      loadingBots,
      submitGenerateLinkButton,
      sortObj,
      activePlatformsKeys,
      buy_type,
    };
  },
});
