
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  ref,
  watchEffect,
} from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ErrorMessage, Field, useForm } from "vee-validate";
import * as Yup from "yup";
import { hideModal } from "@/core/helpers/dom";
import { getIllustrationsPath } from "@/core/helpers/assets";
import { useI18n } from "vue-i18n";
import { platforms } from "@/store/pinia/Platforms";
import { storeToRefs } from "pinia";
import { allPackages } from "@/store/pinia/AllPackages";
import axios from "axios";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import userInfo from "@/core/services/UserInfo";
import verifyEmail from "@/components/VerifyEmail.vue";
import Invoice from "@/components/Invoice.vue";

import { useRoute } from "vue-router";
import { AllBots } from "@/store/pinia/AllBots";
import router from "@/router";

interface Step1 {
  name: string;
  platforms: [];
  discountCode: string;
}

interface Step2 {
  city: string;
  postal_code: string;
  address: string;
  email: string;
}

interface KTCreateApp extends Step1, Step2 {}

export default defineComponent({
  name: "create-app-modal",
  components: {
    Field,
    ErrorMessage,
    verifyEmail,
    Invoice,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: "new",
    },
  },
  setup(props) {
    const _stepperObj = ref<StepperComponent | null>(null);
    const createAppRef = ref<HTMLElement | null>(null);
    const createAppModalRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const route = useRoute();
    const store = useStore();
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const user = computed(() => {
      return userInfo.getUser();
    });

    const formData = ref<KTCreateApp>({
      name: "",
      platforms: [],
      discountCode: "",
      city: user.value.city,
      postal_code: user.value?.postal_code,
      address: user.value?.address,
      email: user.value?.email,
    });

    const dataHandler = ref({
      selectedPackage: {
        id: "",
        platforms_price: [],
      },
      credit: 0,
      bot: "" as any,
      discountCode: "",
      discountPrice: 0,
      appliedDiscountCode: undefined,
      discountStatus: "" as any,
      prices: 0,
      payablePrice: 0,
    });

    const handleBotFromProps = () => {
      if (props.bot) {
        dataHandler.value.bot = props.bot;
        formData.value.name = props.bot.name;
      }
    };

    onUpdated(() => {
      handleBotFromProps();
    });

    onMounted(() => {
      setTimeout(() => {
        handleBotFromProps();
      }, 1000);
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createAppRef.value as HTMLElement
      );
    });

    const createAppSchema = [
      Yup.object({
        name: Yup.string().required().label("subscription title"),
        platforms: Yup.array().min(1).required().label("select platform"),
        discountCode: Yup.string().label("discount_code"),
      }),
      Yup.object({
        city: Yup.string().required().label("city"),
        address: Yup.string().required().label("address"),
        postal_code: Yup.string().required().label("postal_code"),
        email: Yup.string().required().label("email"),
      }),
    ];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2>({
      validationSchema: currentSchema,
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const handleStep = handleSubmit(async (values) => {
      // formData.value = {
      //   ...formData.value,
      //   ...values,
      // };

      currentStepIndex.value++;

      if (
        user.value.city !== formData.value.city ||
        user.value.address !== formData.value.address ||
        user.value.postal_code !== formData.value.postal_code
      ) {
        let result = await updateProfileChanges();
        if (!result) {
          currentStepIndex.value--;
          return;
        }
      }

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const formSubmit = () => {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        hideModal(createAppModalRef.value);
      });
    };

    resetForm({
      values: {
        ...formData.value,
      },
    });

    // ----------------- //
    const platformsHolder = platforms();
    const { platformsList } = storeToRefs(platformsHolder);

    const packageListHolder = allPackages();
    const { packageList } = storeToRefs(packageListHolder);

    const updateProfileChanges = async () => {
      try {
        return await ApiService.post("user/profile", {
          first_name: user.value.first_name,
          last_name: user.value.last_name,
          card_number: user.value.card_number,
          sheba: user.value.sheba,
          gender: user.value.gender,
          mobile: user.value.mobile,
          country_id: user.value.country.alpha2_code,
          address: formData.value.address,
          city: formData.value.city,
          postal_code: formData.value.postal_code,
          email: formData.value.email,
        });
      } catch (e) {
        if (e && e.response && e.response.data)
          store.commit(Mutations.SET_ERROR, e.response?.data);
      }
    };

    const loadingPrice = ref(false);
    const calculatePrice = () => {
      if (Object.keys(formData.value.platforms).length < 1) {
        dataHandler.value.payablePrice = 0;
        dataHandler.value.prices = 0;
        dataHandler.value.discountPrice = 0;
        dataHandler.value.credit = 0;
        return false;
      }

      let source = null as any;
      if (source) source.cancel("Operation canceled by the user.");
      source = axios.CancelToken.source();

      loadingPrice.value = true;
      ApiService.query(`bot/calculate-price`, {
        cancelToken: source.token,
        params: {
          cancelToken: source.token,
          platforms: formData.value.platforms,
          package_id: dataHandler.value.selectedPackage?.id,
          discount_code:
            dataHandler.value.appliedDiscountCode === true &&
            dataHandler.value.discountCode
              ? dataHandler.value.discountCode
              : undefined,
        },
      })
        .then(({ data }) => {
          // dataHandler.value.discountStatus = dataHandler.value.discountCode
          //   ? true
          //   : undefined;

          dataHandler.value.payablePrice = data.data.price.payablePrice;
          dataHandler.value.prices = data.data.price.basePrice;
          dataHandler.value.discountPrice = data.data.price.discountPrice;
          dataHandler.value.credit = data.data.price.userCredit;

          if (Number(data.data.price.discountPrice) > 0) {
            dataHandler.value.discountStatus = true;
          } else {
            dataHandler.value.appliedDiscountCode = undefined;
          }
          loadingPrice.value = false;
        })
        .catch((e) => {
          if (e.response?.data)
            store.commit(Mutations.SET_ERROR, e.response?.data);
          if (dataHandler.value.discountCode) {
            dataHandler.value.discountStatus = "false";
            dataHandler.value.appliedDiscountCode = undefined;
          }
          if (! axios.isCancel(e)) {
            loadingPrice.value = false;
          }
        });
    };

    watchEffect(() => {
      dataHandler.value.selectedPackage = packageList.value
        ? packageList.value[0]
        : {
            id: "",
            platforms_price: [],
          };
    });

    const confirmEmail = (email) => {
      formData.value.email = email;
    };

    const AllBotHolder = AllBots();
    const { fetchBotList } = AllBotHolder;

    const saveBot = async () => {
      try {
        const result = await ApiService.post("bot/create", {
          name: formData.value.name,
        });
        return result.data.data.bot;
      } catch (e) {
        store.commit(Mutations.SET_ERROR, e.response.data);
        if (submitPayWithCredit.value)
          submitPayWithCredit.value?.removeAttribute("data-kt-indicator");
        if (submitPayOnline.value)
          submitPayOnline.value?.removeAttribute("data-kt-indicator");
      }
    };

    const sendParameter = computed(() => {
      return {
        bot_id: dataHandler.value.bot?.bot_id,
        package_id: dataHandler.value.selectedPackage?.id,
        platforms: formData.value.platforms,
        discount_code:
          dataHandler.value.appliedDiscountCode === true &&
          formData.value.discountCode
            ? formData.value.discountCode
            : undefined,
      };
    });

    const submitPayOnline = ref<HTMLElement | null>(null);
    const chargeCredit = async () => {
      // پرداخت آنلاین از درگاه
      submitPayOnline.value?.setAttribute("data-kt-indicator", "on");

      if (!dataHandler.value.bot) dataHandler.value.bot = await saveBot();
      if (!dataHandler.value.bot) return;

      try {
        const result = await ApiService.post(
          "user/credit/charge",
          sendParameter.value
        );
        await fetchBotList();
        window.location.href = result.data.data.port_url;
      } catch ({ response }) {
        if (response && response.data)
          store.commit(Mutations.SET_ERROR, response.data);
      } finally {
        submitPayOnline.value?.removeAttribute("data-kt-indicator");
      }
    };

    const submitPayWithCredit = ref<HTMLElement | null>(null);
    const payWithCredit = async () => {
      // پرداخت با کیف پول
      submitPayWithCredit.value?.setAttribute("data-kt-indicator", "on");
      if (!dataHandler.value.bot) dataHandler.value.bot = await saveBot();
      if (!dataHandler.value.bot) return;

      try {
        const result = await ApiService.post("bot/buy", sendParameter.value);
        await fetchBotList();
        dataHandler.value.bot = result.data.data.bot;
        router.push(
          "/bot-connections/connect/" +
            dataHandler.value.bot?.bot_id +
            `/${formData.value.platforms.length > 0 ? "whatsapp" : "whatsapp"}`
        );
      } catch ({ response }) {
        store.commit(Mutations.SET_ERROR, response.data);
      } finally {
        submitPayWithCredit.value?.removeAttribute("data-kt-indicator");
      }
    };

    const invoiceRef = ref<HTMLButtonElement | null>(null);

    const print = () => {
      if (invoiceRef.value) {
        const invoiceContent = invoiceRef.value.innerHTML;
        const originalContent = document.body.innerHTML;
        document.body.innerHTML = invoiceContent;
        window.print();
        document.body.innerHTML = originalContent;
      }
    };

    return {
      handleStep,
      formSubmit,
      previousStep,
      createAppRef,
      currentStepIndex,
      totalSteps,
      createAppModalRef,
      getIllustrationsPath,
      translate,

      formData,
      dataHandler,
      platformsList,
      packageList,

      calculatePrice,
      user,
      route,
      confirmEmail,

      loadingPrice,

      submitPayWithCredit,
      chargeCredit,
      submitPayOnline,
      payWithCredit,

      invoiceRef,
      print,
    };
  },
});
