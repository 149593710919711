import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fw-bold text-center text-dark fs-6 mb-1 ms-1"
}
const _hoisted_2 = {
  class: "d-flex flex-wrap flex-row-reverse flex-stack justify-content-center",
  style: {"direction":"rtl !important"}
}
const _hoisted_3 = {
  key: 1,
  class: "d-flex flex-wrap flex-row-reverse flex-stack justify-content-center",
  style: {"direction":"rtl !important"}
}
const _hoisted_4 = ["onUpdate:modelValue", "placeholder", "id", "data-testid", "onInput"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_input_digit = _resolveDirective("input-digit")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.translate("Enter the security code")), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.codetype === 'string')
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.string_code) = $event)),
            type: "text",
            class: "form-control bg-transparent h-40px fs-2 text-center mx-1 my-1"
          }, null, 512)), [
            [_vModelText, _ctx.string_code]
          ])
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.max, (len, index) => {
              return _withDirectives((_openBlock(), _createElementBlock("input", {
                key: len,
                "onUpdate:modelValue": ($event: any) => ((_ctx.codes[index]) = $event),
                type: "number",
                placeholder: len,
                maxlength: "1",
                autocomplete: "off",
                class: "form-control bg-transparent h-40px w-40px fs-2 text-center mx-1 my-1",
                id: `input_${index}`,
                "data-testid": 'otp' + index,
                onInput: ($event: any) => (
            _ctx.handleInputElsInput($event, index - 1)
          ),
                onKeyup: _cache[1] || (_cache[1] = ($event: any) => {
            _ctx.backSpace($event);_ctx.check();})
              }, null, 40, _hoisted_4)), [
                [_vModelText, _ctx.codes[index]],
                [_directive_input_digit]
              ])
            }), 128))
          ]))
    ])
  ]))
}