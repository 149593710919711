import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "table-responsive rounded mb-9"
}
const _hoisted_2 = { class: "d-flex flex-wrap" }
const _hoisted_3 = { class: "col-3" }
const _hoisted_4 = { class: "d-flex flex-column align-items-center justify-content-center" }
const _hoisted_5 = { class: "symbol symbol-30px mb-2" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "fs-7" }
const _hoisted_8 = { class: "col-3" }
const _hoisted_9 = { class: "fs-8 text-gray-600 mb-3" }
const _hoisted_10 = { class: "col-3" }
const _hoisted_11 = { class: "fs-8 text-gray-600 mb-3" }
const _hoisted_12 = { class: "col-3" }
const _hoisted_13 = { class: "fs-8 text-gray-600 mb-3" }
const _hoisted_14 = {
  class: "col-12 fs-7 text-gray-700 mt-3",
  style: {"text-align":"right"}
}
const _hoisted_15 = { class: "text-bold text-gray-900" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { style: {"word-break":"break-word"} }
const _hoisted_19 = {
  key: 0,
  class: "col-12 fs-7 text-gray-700 mt-1",
  style: {"text-align":"right"}
}
const _hoisted_20 = { class: "text-gray-900" }
const _hoisted_21 = { class: "mx-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_card = _resolveComponent("el-card")!

  return ((_ctx.platforms && _ctx.platforms.length > 0) || _ctx.showAllInBoundleMode)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.showAllInBoundleMode
        ? Object.keys(_ctx.platformsList)
        : _ctx.platforms, (plat) => {
          return (_openBlock(), _createBlock(_component_el_card, {
            key: plat,
            class: "my-2"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", _hoisted_5, [
                      _createElementVNode("img", {
                        src: _ctx.platformsList[plat].logo,
                        alt: ""
                      }, null, 8, _hoisted_6)
                    ]),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.translate(plat)), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translate("bulk-message")), 1),
                  _createElementVNode("i", {
                    class: _normalizeClass(["bi fs-1", [
              _ctx.platformsList[plat].bulk_message_status
                ? 'bi-check-circle-fill text-success'
                : 'bi-x-circle-fill text-danger',
            ]])
                  }, null, 2)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.translate("Autoresponder")), 1),
                  _createElementVNode("i", {
                    class: _normalizeClass(["bi fs-1", [
              _ctx.platformsList[plat].secretary_status
                ? 'bi-check-circle-fill text-success'
                : 'bi-x-circle-fill text-danger',
            ]])
                  }, null, 2)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.translate("api")), 1),
                  _createElementVNode("i", {
                    class: _normalizeClass(["bi fs-1", [
              _ctx.platformsList[plat].api_status
                ? 'bi-check-circle-fill text-success'
                : 'bi-x-circle-fill text-danger',
            ]])
                  }, null, 2)
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.translate("sending limitation")) + " : ", 1),
                  (!Array.isArray(_ctx.platformsList[plat].sending_limitation))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.translate(_ctx.platformsList[plat].sending_limitation)), 1))
                    : _createCommentVNode("", true),
                  (Array.isArray(_ctx.platformsList[plat].sending_limitation))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createElementVNode("ul", _hoisted_18, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.platformsList[plat].sending_limitation, (item) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: item.id
                            }, _toDisplayString(item), 1))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.platformsList[plat].description)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.translate("explanation")) + " :", 1),
                      _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.platformsList[plat].description), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}