
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import Code from "@/components/Phone/Code.vue";
import userInfo from "@/core/services/UserInfo";

export default defineComponent({
  name: "kt-verify-email",
  components: { Code },
  emit: ["confirmEmail"],
  setup(props, { emit }) {
    const store = useStore();
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const submitButton = ref<HTMLButtonElement | null>(null);
    const submitButton2 = ref<HTMLButtonElement | null>(null);

    const holder = ref({
      email: "",
      code: "",
      step: "confirm_email",
    });

    const sendCode = () => {
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      ApiService.post("auth/send-code", {
        email: holder.value.email,
      })
        .then(() => {
          holder.value.step = "confirm_code";
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          if (submitButton.value) {
            //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
              submitButton.value!.disabled = false;
          }
        });
    };

    const changeEmail = () => {
      if (submitButton2.value) {
        // eslint-disable-next-line
        submitButton2.value!.disabled = true;
        // Activate indicator
        submitButton2.value.setAttribute("data-kt-indicator", "on");
      }

      ApiService.post("user/email/change", {
        email: holder.value.email,
        code: holder.value.code,
      })
        .then(() => {
          emit("confirmEmail", holder.value.email);
          holder.value.step = "success";
          let user = userInfo.getUser();
          user["email"] = holder.value.email;
          userInfo.saveUser(user);
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          if (submitButton2.value) {
            //Deactivate indicator
            submitButton2.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
              submitButton2.value!.disabled = false;
          }
        });
    };

    const getCodes = (newCode) => {
      holder.value.code = newCode;
    };

    return {
      translate,
      sendCode,
      holder,
      getCodes,

      submitButton,
      submitButton2,
      changeEmail,
    };
  },
});
